<template>
  <div v-if="!disShow" style="position: relative">
    <!-- 编辑模式 -->
    <div
      :class="[labelPosition == 'left' ? 'label-left' : '']"
      v-if="!textShow && !isText"
    >
      <!-- 标题 -->
      <div
        class="label"
        :style="{
          margin: defaultConfig.label ? defaultConfig.labelPadding : '0',
          display: labelPosition == 'left' ? 'inline-block' : 'block',
          marginRight: labelPosition == 'left' ? '8px' : '',
        }"
      >
        <div>
          <span v-if="defaultConfig.required" style="color: #c94242">*</span
          >{{ defaultConfig.label }}
        </div>
      </div>
      <template v-if="defaultConfig.type == 'slot'">
        <div :style="{ width: defaultConfig.width || 'fit-content' }">
          <slot></slot>
        </div>
      </template>
      <template v-if="defaultConfig.type == 'input'">
        <el-input
          :style="{ width: defaultConfig.width || 'fit-content' }"
          v-model="innerValue"
          :placeholder="defaultConfig.placeholder || '请输入'"
          :disabled="defaultConfig.disabled"
        >
          <template slot="append">
            <slot name="inputAppend"></slot>
          </template>
        </el-input>
      </template>
      <template v-if="defaultConfig.type == 'searchInput'">
        <el-autocomplete
          :style="{
            width: defaultConfig.width || 'fit-content',
            flex: defaultConfig.width ? '' : '1',
          }"
          v-model="innerValue"
          :fetch-suggestions="querySearchAsync"
          @select="handleSelectQuerySearchAsync"
        >
        </el-autocomplete>
      </template>
      <template v-if="defaultConfig.type == 'textarea'">
        <el-input
          type="textarea"
          :style="{
            width: defaultConfig.width || 'fit-content',
            flex: defaultConfig.width ? '' : '1',
          }"
          v-model="innerValue"
          :placeholder="defaultConfig.placeholder || '请填写'"
          :disabled="defaultConfig.disabled"
        ></el-input>
      </template>
      <template v-if="defaultConfig.type == 'select'">
        <el-select
          clearable
          filterable
          :disabled="defaultConfig.disabled"
          :style="{ width: defaultConfig.width || 'fit-content' }"
          v-model="innerValue"
          :placeholder="defaultConfig.placeholder || '请输选择'"
        >
          <el-option
            v-for="(item, index) in defaultConfig.option"
            :label="item[optionConfig.label]"
            :value="item[optionConfig.value]"
            :key="index"
          ></el-option>
        </el-select>
      </template>
      <template v-if="defaultConfig.type == 'radio'">
        <div :style="{ width: defaultConfig.width || 'fit-content' }">
          <el-radio
            :disabled="defaultConfig.disabled"
            v-for="(item, index) in defaultConfig.option"
            v-model="innerValue"
            :label="item[optionConfig.value]"
            :key="index"
            >{{ item[optionConfig.label] }}</el-radio
          >
        </div>
      </template>
      <template v-if="defaultConfig.type == 'inputNumber'">
        <el-input-number
          :disabled="defaultConfig.disabled"
          :style="{ width: defaultConfig.width || 'fit-content' }"
          v-model="innerValue"
          :min="0"
        >
        </el-input-number>
      </template>
      <template v-if="defaultConfig.type == 'datePicker'">
        <el-date-picker
          :disabled="defaultConfig.disabled"
          :style="{ width: defaultConfig.width || 'fit-content' }"
          v-model="innerValue"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
      </template>
      <template v-if="defaultConfig.type == 'daterange'">
        <el-date-picker
          :disabled="defaultConfig.disabled"
          :style="{ width: defaultConfig.width || 'fit-content' }"
          v-model="innerValue"
          type="daterange"
          placeholder="选择日期"
        >
        </el-date-picker>
      </template>
      <template v-if="defaultConfig.type == 'location'">
        <el-input
          :disabled="defaultConfig.disabled"
          :style="{ width: defaultConfig.width || 'fit-content' }"
          v-model="innerValue"
          :placeholder="defaultConfig.placeholder"
          ><template v-if="!defaultConfig.disabled" slot="append"
            ><img
              src="@/assets/images/policy/policyPosition.png"
              @click="isPositionDialog = true"
              style="width: 20px; height: 20px" /></template
        ></el-input>
        <positionDialog
          v-model="isPositionDialog"
          type="addressObj"
          :addressValue="addressValue"
          @submit="handleAddressVal"
        ></positionDialog>
      </template>
      <template v-if="defaultConfig.type == 'distpicker'">
        <el-cascader
          ref="distpicker"
          :style="{ width: defaultConfig.width || 'fit-content' }"
          :options="areaSelectData"
          :change-on-select="true"
          :clearable="true"
          :filterable="true"
          v-model="innerValue"
          @change="handleRegionChange"
          @visible-change="visibleChangeCascader"
        />
      </template>
    </div>
    <!-- 文字显示模式 -->
    <div
      class="label"
      :style="{
        display: 'flex',
        marginTop: defaultConfig.labelPadding,
      }"
      v-if="textShow || isText"
    >
      <!-- 标题 -->
      <div style="display: flex; width: fit-content">
        <span v-if="defaultConfig.required" style="color: #c94242">*</span>
        <div>{{ defaultConfig.label }}</div>
      </div>
      <div v-if="defaultConfig.type == 'textarea'" class="text-show text-show2">
        {{ innerText || "---" }}
      </div>
      <div style="width: fit-content" v-else class="text-show">
        {{ innerText || "---" }}
      </div>
    </div>
  </div>
</template>

<script>
import { provinceAndCityData, codeToText } from "element-china-area-data";
import positionDialog from "@/views/policyManagement/components/positionDialog.vue";
import dayjs from "dayjs";
import { areaTextToCode } from "../js/area.js";
export default {
  name: "TextInput",
  model: {
    prop: "modeValue",
    event: "update:modelValue",
  },
  components: { positionDialog },
  props: {
    optionConfig: {
      type: Object,
      default: () => {
        return {
          value: "value",
          label: "label",
        };
      },
    },
    config: {
      type: Object,
      default: () => {
        return {};
      },
    },
    modeValue: {
      type: [String, Number, Array, Object, Boolean],
      default: () => {
        return "";
      },
    },
    isText: {
      type: Boolean,
      default: false,
    },
    disShow: {
      type: Boolean,
      default: false,
    },
    labelPosition: {
      type: String,
      default: "top",
    },
    querySearchAsync: {
      type: Function,
      default: (queryString, cb) => {
        cb([]);
        return;
      },
    },
    form: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    config: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        this.defaultConfig = { ...this.defaultConfig, ...newValue };
      },
    },
  },
  computed: {
    innerText: {
      get() {
        if (
          this.defaultConfig.type == "slot" ||
          this.defaultConfig.type == "input" ||
          this.defaultConfig.type == "inputNumber" ||
          this.defaultConfig.type == "datePicker" ||
          this.defaultConfig.type == "textarea" ||
          this.defaultConfig.type == "searchInput" ||
          this.defaultConfig.type == "location"
        ) {
          return this.modeValue;
        } else if (
          this.defaultConfig.type == "radio" ||
          this.defaultConfig.type == "select"
        ) {
          return this.findText(this.innerValue);
        } else if (this.defaultConfig.type == "daterange") {
          //let dates = this.modeValue;
          return `${this.form[this.defaultConfig.daterangeKeys[0]]}~${
            this.form[this.defaultConfig.daterangeKeys[1]]
          }`;
        } else if (this.defaultConfig.type == "distpicker") {
          // let dist = [];
          // Object.keys(this.distForm).forEach((key) => {
          //   if (this.distForm[key]) {
          //     dist.push(this.distForm[key]);
          //   }
          // });
          return `${this.form[this.defaultConfig.regionKeys[0]]}/${
            this.form[this.defaultConfig.regionKeys[1]]
          }`;
        }
      },
      set(val) {},
    },
    innerValue: {
      get() {
        if (this.defaultConfig.type == "distpicker") {
          // console.log("get");
          let res = areaTextToCode([
            this.form[this.defaultConfig.regionKeys[0]],
            this.form[this.defaultConfig.regionKeys[1]],
          ]);
          return res;
        } else if (this.defaultConfig.type == "daterange") {
          return [
            this.form[this.defaultConfig.daterangeKeys[0]],
            this.form[this.defaultConfig.daterangeKeys[1]],
          ];
        }
        return this.modeValue;
      },
      set(val) {
        let returnVal = val;
        if (
          this.defaultConfig.type == "input" ||
          this.defaultConfig.type == "inputNumber" ||
          this.defaultConfig.type == "textarea" ||
          this.defaultConfig.type == "searchInput"
        ) {
          if (this.defaultConfig.isNum) {
            // let RegExp = /^[+-]?(0|([1-9]\d*))(\.\d+)?$/g;
            let RegExp = /[^\d.]/g;
            returnVal = returnVal.replace(RegExp, "");
          }

          this.$emit("update:modelValue", returnVal);
          if (this.defaultConfig.modelTextKey) {
            this.$emit("returnVal", {
              data: returnVal,
              type: this.defaultConfig.modelTextKey,
              comType: this.defaultConfig.type,
            });
          }
        } else if (this.defaultConfig.type == "datePicker") {
          this.$emit(
            "update:modelValue",
            dayjs(returnVal).format(this.defaultConfig.dateFormat)
          );
        } else if (
          this.defaultConfig.type == "radio" ||
          this.defaultConfig.type == "select"
        ) {
          this.$emit("update:modelValue", returnVal);
          this.$emit("returnVal", {
            data: this.findText(returnVal, true),
            type: this.defaultConfig.modelTextKey,
            comType: this.defaultConfig.type,
          });
        } else if (this.defaultConfig.type == "daterange") {
          // FIXME 清空会触发两次
          let dates = [];
          returnVal?.forEach((date) => {
            dates.push(dayjs(date).format(this.defaultConfig.dateFormat));
          });
          returnVal = dates;
          if (this.defaultConfig.daterangeKeys.length > 0) {
            let deal = {};
            this.defaultConfig.daterangeKeys.forEach((key, index) => {
              if (this.defaultConfig.daterangeFormats[index]) {
                deal[key] = dayjs(returnVal[index]).format(
                  this.defaultConfig.daterangeFormats[index]
                );
              } else {
                deal[key] = returnVal[index];
              }
            });
            if (!returnVal.length) {
              this.defaultConfig.daterangeKeys.forEach((key, index) => {
                deal[key] = "";
              });
            }
            this.$emit("dataDeal", deal);
            this.$emit("returnVal", {
              data: {
                dates: deal,
                daterangeKeys: this.defaultConfig.daterangeKeys,
              },
              type: this.defaultConfig.modelTextKey,
              comType: this.defaultConfig.type,
            });
          }
          this.$emit("update:modelValue", returnVal);
        } else if (this.defaultConfig.type == "distpicker") {
          // console.log("set");
          this.$emit("update:modelValue", returnVal);
          //this.handleRegionChange(returnVal);
        }
      },
    },
  },
  data() {
    return {
      isPositionDialog: false,
      text: "",
      //innerText: "",
      textShow: false,
      addressValue: "",
      distForm: {
        province: "",
        city: "",
        area: "",
      },
      areaSelectData: provinceAndCityData,
      defaultConfig: {
        label: "",
        labelPadding: "24px 0 10px 0",
        placeholder: "",
        value: "",
        disabled: false,
        readonly: false,
        clearable: false,
        maxlength: 0,
        minlength: 0,
        showWordLimit: false,
        autosize: false,
        autofocus: false,
        width: "280px",
        rows: 0,
        // input select datePicker radio inputNumber location textarea daterange distpicker searchInput
        type: "",
        daterangeKeys: [],
        daterangeParams: [],
        dateFormat: "YYYY-MM-DD 00:00:00",
        daterangeFormats: ["YYYY-MM-DD 00:00:00", "YYYY-MM-DD 23:59:59"],
        regionKeys: ["province ", "city"],
        //slect radio 对应key 的 value
        modelTextKey: "",
        // input textarea 用于判断是否是数字
        isNum: false,
        required: false,
        option: [
          // {
          //   label: "选项1",
          //   value: "1",
          // },
          // {
          //   label: "选项2",
          //   value: "2",
          // },
        ],
      },
    };
  },
  methods: {
    findText(value = "", reobj = false) {
      let obj = this.defaultConfig.option?.find((item) => {
        return item[this.optionConfig.value] == value;
      });
      if (reobj) {
        return obj || {};
      }
      if (obj) {
        return obj[this.optionConfig.label];
      } else {
        return this.innerValue;
      }
    },
    handleAddressVal(e) {
      if (this.defaultConfig.modelKey) {
        this.$emit("update:modelValue", e.completeAddress);
      }
      this.isPositionDialog = false;
    },
    handleRegionChange(e = []) {
      let res = {};
      this.defaultConfig.regionKeys.forEach((key, index) => {
        res[key] = codeToText[e[index]];
      });
      this.$emit("returnVal", {
        data: res,
        type: this.defaultConfig.modelTextKey,
        comType: this.defaultConfig.type,
      });
    },
    handleSelectQuerySearchAsync(e) {
      this.$emit("handleSelectQuerySearchAsync", e);
    },
    // 处理级联省市高度适应
    visibleChangeCascader() {
      const cascader = document.getElementsByClassName("el-cascader-panel");
      cascader[0].style.height = "auto";
      cascader[0].style.maxHeight = "500px";
    },
  },
};
</script>

<style lang="scss" scoped>
.text-show {
  width: 280px;
  word-wrap: break-word;
}
.text-show2 {
  flex: 1;
}
.label {
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  display: flex;
  align-items: center;
}
.label-left {
  text-align: end;
}
</style>
